import styled, { keyframes } from 'styled-components';
import { AppImage, Body, BodyContainer } from '../../App.styles';

const slideIn = keyframes`
  0%   { bottom: -100%; }
  100% { bottom: 0; }
`;

const textSlideIn = keyframes`
  0%   { opacity: 0; transform: translateY(50px) }
  100% { opacity: 1; transform: translateY(0px) }
`;

export const HomeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HomeImageContainer = styled.div`
  width: 100%;
`;

export const HomeImage = styled(AppImage)``;

export const HomeBodyContainer = styled(BodyContainer)`
  width: 95%;
  margin: 0 auto;
  background-color: transparent;
  animation: ${slideIn} 0.5s 0.3s backwards;
`;
export const HomeBody = styled(Body)`
  width: 100%;
  animation: ${textSlideIn} 0.8s cubic-bezier(0, 0, 0.1, 1) 1s backwards;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin: auto;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xl}px;
  font-weight: 500;
  padding: 20px;
  /*font-family: ${({ theme }) => theme.fontFamily.visbyCF};*/
`;
