import { atom } from 'recoil';

export const navOpenState = atom({
  key: 'navOpen',
  default: false,
});
export const logoHeightState = atom({
  key: 'logoHeightState',
  height: 200,
});
