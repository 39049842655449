import React, { useEffect, useRef } from 'react';
import { NavConatiner, NavLink, MainNav, SocialIcon } from './NavBar.styles';
import MenuBurger from '../MenuBurger';
import fbIcon from './assets/fb.svg';
import igIcon from './assets/ig.svg';
import { useRecoilState } from 'recoil';
import { navOpenState } from '../../state/app-state';

const NavBar = () => {
  const [navOpen, setNav] = useRecoilState(navOpenState);
  const navBar = useRef();
  const handleExpand = (expand) => {
    setNav(!expand);
  };

  const handleMenuClose = (e) => {
    if (e.target && e.target.matches('a')) {
      setNav(false);
    }
  };

  const handleClickOutside = (e) => {
    if (navBar.current.contains(e.target)) {
      return;
    }
    setNav(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <NavConatiner ref={navBar} width={[1, 1 / 2, 1 / 4]} onClick={handleMenuClose}>
      <MenuBurger toggleExpand={handleExpand} expand={navOpen} />
      <MainNav navOpen={navOpen}>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/work">Our Work</NavLink>
        </li>
        <li>
          <NavLink to="/about-us">About us</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact us</NavLink>
        </li>
        <li>
          <a href="https://www.facebook.com/Blush-Nail-Bar-2738822946160345/" target="_blank" rel="noopener noreferrer">
            <SocialIcon src={fbIcon} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/blushnailbarlondon/" target="_blank" rel="noopener noreferrer">
            <SocialIcon src={igIcon} alt="facebook" />
          </a>
        </li>
      </MainNav>
    </NavConatiner>
  );
};

export default NavBar;
