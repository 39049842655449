import React from 'react';
import {
  ProjectsContainer,
  ProjectsTitle,
  ProjectsThumbContainer,
  ProjectsThumb,
  ProjectsThumbImage,
  ProjectsBody,
  ProjectsBodyTitle,
  ProjectsDescription,
} from './Projects.styles';
import { useRecoilValue } from 'recoil';
import { logoHeightState } from '../../state/app-state';

const Projects = () => {
  const logoHeight = useRecoilValue(logoHeightState);
  return (
    <ProjectsContainer logoHeight={logoHeight}>
      <ProjectsTitle>OUR PROJECTS</ProjectsTitle>
      <ProjectsDescription>
        Choosing Campaign Strategy We create maximum exposure across digital platforms with interactive and innovative
        viral campaigns and artificial intelligence.
        <br />
        <br />
        Our focus on high quality virals, infused with technical coding is cutting edge as outlined by a list of clients
        we have serviced, e.g MTV,Warner Bros.
        <br />
        <br />
        By working with a robust team we ensure targeted campaigns that will reach the following; It is clear that
        voting reach becomes wider once the viral campaign is implemented. The focal point of a good campaign is the
        engagement with the voters.
        <br />
        <br />
        Our social media marketing strategy helps you to win voters and leverage the digital influence that will amplify
        the results you envisioned.
      </ProjectsDescription>

      <ProjectsThumbContainer>
        <ProjectsThumb href="https://saibotlive.com/ask-atiku-part1" target="_blank">
          <ProjectsThumbImage src="/img/ask-atiku.jpg" />
          <ProjectsBody>
            <ProjectsBodyTitle>Ask Atiku Yourself</ProjectsBodyTitle>
          </ProjectsBody>
        </ProjectsThumb>
        <ProjectsThumb href="https://saibotlive.com/fb-thankyou/" target="_blank">
          <ProjectsThumbImage src="/img/atiku-fb.jpg" />
          <ProjectsBodyTitle>Atiku Thanks you</ProjectsBodyTitle>
        </ProjectsThumb>
        <ProjectsThumb href="https://saibotlive.com/buhari_special/" target="_blank">
          <ProjectsThumbImage src="/img/buhari_special.jpg" />
          <ProjectsBodyTitle>A Special Message</ProjectsBodyTitle>
        </ProjectsThumb>
        <ProjectsThumb href="https://saibotlive.com/gej_launch" target="_blank">
          <ProjectsThumbImage src="/img/gej_launch.jpg" />

          <ProjectsBodyTitle>GEJ Launch</ProjectsBodyTitle>
        </ProjectsThumb>
        <ProjectsThumb href="https://saibotlive.com/gej_board1" target="_blank">
          <ProjectsThumbImage src="/img/gej_board.jpg" />

          <ProjectsBodyTitle>GEJ Billboard</ProjectsBodyTitle>
        </ProjectsThumb>
      </ProjectsThumbContainer>
    </ProjectsContainer>
  );
};

export default Projects;
