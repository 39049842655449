import React from 'react';
import { MainContainer, LogoContainer, Logo, Shutter } from './Main.styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navOpenState, logoHeightState } from '../../state/app-state';
import { useEffect } from 'react';
import { useRef } from 'react';

const Main = ({ children }) => {
  const logoContainerRef = useRef(null)
  const logoRef = useRef(null)
  const setLogoHeight = useSetRecoilState(logoHeightState)
  const navOpen = useRecoilValue(navOpenState);

  useEffect(() => {
   
    logoRef.current.onload = () => {
      setLogoHeight(logoContainerRef.current.offsetHeight)
    }
   // 
  }, [logoRef, setLogoHeight])

  return (
    <MainContainer navOpen={navOpen}>
      {children}
      <Shutter />
      <LogoContainer ref={logoContainerRef}   >
        <Logo ref={logoRef}  src="/img/logo-white.svg" className="App-logo" alt="logo" />
      </LogoContainer>
    </MainContainer>
  );
};

export default Main;
