import styled from 'styled-components';

export const BurgerContainer = styled.div`
  position: absolute;
  left: ${({ theme, navOpen }) => (navOpen ? `${2 * theme.breakpoints.xs}px` : `${theme.breakpoints.xs}px`)};
  padding: 18.5px;
  background-color: white;
  z-index: 999;
  transition: left 0.5s;
  border-radius: 0 5px 5px 0;
`;

export const Bar = styled.div`
  overflow: hidden;
  position: relative;
  width: 30px;
  height: 4px;
  background-color: ${(props) => props.theme.color.black};
  margin: 4px 0;
  opacity: ${(props) => (props.navOpen ? '0' : '1')};
  transition: transform 0.3s ease;
  border-radius: 2px;
  &:after {
    content: '';
    position: relative;
    background-color: lightgrey;
    display: block;
    width: 100%;
    left: 100%;
    height: 100%;
    transition: left 0.3s ${({ delay }) => delay}s;
  }
`;

export const Burger = styled.div`
  cursor: pointer;
  ${Bar} {
    opacity: ${({ navOpen }) => (navOpen ? 0 : 1)};
    &:first-child {
      margin-bottom: 0;
      opacity: 1;
      transform: ${(props) => (props.navOpen ? 'rotate(-45deg) translate(-8px, 0px)' : 'none')};
    }

    &:last-child {
      margin-bottom: 0;
      opacity: 1;
      transform: ${(props) => (props.navOpen ? ' rotate(45deg) translate(-10px, -4px)' : 'none')};
    }
  }

  &:hover {
    ${Bar} {
      &:after {
        left: ${({ navOpen }) => (navOpen ? '100%' : '0')};
      }
    }
  }
`;
