import React from 'react';
import { AboutUsContainer, AboutImage, AboutBody, AboutBodyContainer, AboutTitle } from './AboutUs.styles';

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <AboutImage imgSrc="/img/aboutus.jpg" />
      <AboutBodyContainer>
        <AboutTitle>ABOUT US</AboutTitle>
        <AboutBody>
          <h3>WHO WE ARE</h3>
          We are a multi-faceted digital innovation company with over 10 years experience within the industry.
          <br />
          <br />
          We grasp the link between an audience and communicating through digital solutions in a technologically
          advancing world.
          <h3>OUR MISSION</h3>
          we aim to streamline a cohesive and persuasive political message to your target audience. We want to see you
          succeed by leveraging our experience to achieve targeted results.
        </AboutBody>
      </AboutBodyContainer>
    </AboutUsContainer>
  );
};

export default AboutUs;
