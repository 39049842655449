import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import { RecoilRoot } from 'recoil';
import Home from './components/Home';
import { mainTheme } from './theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './App.styles';
import { AppContainer } from './App.styles';
import Main from './components/Main';
import AboutUs from './components/AboutUs';
import Projects from './components/Projects';
import Contact from './components/Contact';

function App() {
  return (
    <Router>
      <ThemeProvider theme={mainTheme}>
        <RecoilRoot>
          <GlobalStyle />
          <NavBar />
          <AppContainer>
            <Main>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/about-us">
                  <AboutUs />
                </Route>
                <Route path="/work">
                  <Projects />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
              </Switch>
            </Main>
          </AppContainer>
        </RecoilRoot>
      </ThemeProvider>
    </Router>
  );
}

export default App;
