import React from 'react';

import { BurgerContainer, Burger, Bar } from './MenuBurger.styles';
import { useRecoilState } from 'recoil';
import { navOpenState } from '../../state/app-state';

const MenuBurger = () => {
  const [navOpen, setNav] = useRecoilState(navOpenState);
  const handleClick = () => {
    setNav(!navOpen);
  };

  return (
    <BurgerContainer onClick={handleClick} navOpen={navOpen}>
      <Burger navOpen={navOpen}>
        <Bar delay={0} />
        <Bar delay={0.1} />
        <Bar delay={0.2} />
      </Burger>
    </BurgerContainer>
  );
};

export default MenuBurger;
