import styled, { keyframes } from 'styled-components';
import { AppImage, BodyContainer, Body, Title } from '../../App.styles';
const slideIn = keyframes`
  0%   { bottom: -100%; }
  100% { bottom: 0; }
`;

const bgSlideIn = keyframes`
  0%   {  top: 0%; }
  100% {  top: -30%; }
`;

const textSlideIn = keyframes`
  0%   { opacity: 0; transform: translateY(50px) }
  100% { opacity: 1; transform: translateY(0px) }
`;

export const ContactUsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  a {
    color: inherit;
  }
`;

export const ContactImage = styled(AppImage)`
  animation: ${bgSlideIn} 0.8s cubic-bezier(0, 0, 0.1, 1) 0.8s forwards;
`;

export const ContactTitle = styled(Title)`
  margin-bottom: 60px;
`;

export const ContactBodyContainer = styled(BodyContainer)`
  animation: ${slideIn} 0.5s 0.3s backwards;
`;
export const ContactBody = styled(Body)`
  text-align: center;
  margin-bottom: 30px;
  animation: ${textSlideIn} 0.8s cubic-bezier(0, 0, 0.1, 1) 1s backwards;
`;
