import styled, { keyframes } from 'styled-components';

export const slideUp = keyframes`
  0%   { height: 100%; }
  100% { height: 0; }
`;

export const logoSlideUp = keyframes`
  0%   { top: 50%; transform: translateY(-50%) }
  100% { top: 20px; transform: translateY(0%)}
`;

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: ${({ theme, navOpen }) => (navOpen ? theme.breakpoints.xs : 0)}px;
  transition: left 0.5s;

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${({ navOpen }) => (navOpen ? 1 : 0)};
    pointer-events: ${({ navOpen }) => (navOpen ? 'default' : 'none')};
    transition: opacity 0.5s;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  max-width: 500px;
  width: 60%;
  justify-content: center;
  grid-column: 2;
  animation: ${logoSlideUp} 0.5s 1s backwards;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px;
`;

export const Logo = styled.img`
  max-width: 100%;
`;

export const Shutter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  transition: height 0.5s;
  animation: ${slideUp} 0.5s 1.2s forwards;
`;
