import styled, { createGlobalStyle } from 'styled-components';
import AkkuratePro1 from './assets/fonts/AkkuratPro-Regular.woff';
import AkkuratePro2 from './assets/fonts/AkkuratPro-Regular.woff2';
import AkkurateLight1 from './assets/fonts/Akkurat-Light.woff';
import AkkurateLight2 from './assets/fonts/Akkurat-Light.woff2';
import AkkurateBold1 from './assets/fonts/Akkurat-Bold.woff';
import AkkurateBold2 from './assets/fonts/Akkurat-Bold.woff2';
import VisbyCFBold1 from './assets/fonts/VisbyCF-Bold.woff';
import VisbyCFBold2 from './assets/fonts/VisbyCF-Bold.woff2';

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'Visby CF';
    src: url(${VisbyCFBold1}) format('woff2'),
        url(${VisbyCFBold2}) format('woff');
    font-weight: bold;
    font-style: normal;
  }


  @font-face {
    font-family: 'Akkurat';
    src: url(${AkkurateLight1}) format('woff2'),
        url(${AkkurateLight2}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

@font-face {
    font-family: 'Akkurat';
    src: url(${AkkurateBold1}) format('woff2'),
        url(${AkkurateBold2}) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat';
    src: url(${AkkuratePro1}) format('woff2'),
        url(${AkkuratePro2}) format('woff');
    font-weight: normal;
    font-style: normal;
}


  html,
  body {
    background-color: ${(props) => props.theme.color.white};   
    color: ${({ theme }) => theme.color.black};
    -webkit-overflow-scrolling: touch;
  }
  html {
    overflow: auto;
    height: 100%; 
  }
    body {
      margin: 0;
      width: 100%;
      padding: 0;
      overflow-x: hidden;
      font-family: ${(props) =>
        props.theme.fontFamily.akkurat};/*-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;*/
        font-weight: ${({ theme }) => theme.fontWeight.light};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    #root {
      position: relative;
      width: 100%;
    }


    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    .fade-enter {
      opacity: 0;
    }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-active {
    opacity: 0;
    transition: opacity 400ms ease;
  }
`;

export const AppContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const LogoContainer = styled.div`
  max-width: 500px;
  width: 70%;
  justify-content: center;
  grid-column: 2;
`;

export const Logo = styled.img`
  max-width: 100%;
`;

export const AppImage = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-image: url(${({ imgSrc }) => imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
`;

export const BodyContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 60%;
  padding: 30px 20px 20px;
  background-color: ${({ theme }) => theme.color.white};
  bottom: 0;
`;

export const Body = styled.div`
  position: relative;
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

export const Title = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.visbyCF};
  letter-spacing: 5px;
  font-size: ${({ theme }) => theme.fontSize.xl}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.space[2]}px;

  @media (min-width: 769px) {
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
  }
`;
