import styled from 'styled-components';
import { Title, Body } from '../../App.styles';

export const ProjectsContainer = styled.div`
  position: relative;
  width: 100%;
  top: calc(10px + ${({logoHeight}) => logoHeight}px);
  padding: 30px 20px 20px;
`;

export const ProjectsTitle = styled(Title)`
  margin-bottom: 20px;
`;
export const ProjectsDescription = styled(Body)`
  margin-bottom: 60px;
  max-width: 800px;
`;

export const ProjectsThumbContainer = styled.div`
  position: relative;
  display: block;

  width: 100%;
`;
export const ProjectsThumb = styled.a`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.visbyCF};
  text-decoration: none;
  color: inherit;
  /*border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);*/
  margin-bottom: 30px;
`;
export const ProjectsThumbImage = styled.img`
  position: relative;
  display: block;
  width: 100%;
  max-width: 500px;
  margin-right: 20px;
`;

export const ProjectsBody = styled(Body)`
  position: relative;
  display: block;
`;

export const ProjectsBodyTitle = styled.div`
  position: relative;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
`;
