import React from 'react';
import { ContactUsContainer, ContactImage, ContactBody, ContactBodyContainer, ContactTitle } from './Contact.styles';
import { MarkerIcon, PhoneIcon } from '../icons';

const ContactUs = () => {
  return (
    <ContactUsContainer>
      <ContactImage imgSrc="/img/contact.jpg" />
      <ContactBodyContainer>
        <ContactTitle>CONTACT US</ContactTitle>
        <ContactBody>
          <MarkerIcon></MarkerIcon>
        </ContactBody>
        <ContactBody>Lagos.Abuja.London</ContactBody>
        <br />
        <br />
        <ContactBody>
          <PhoneIcon />
        </ContactBody>
        <ContactBody>
          Kigali <a href="tel:+2507866347776">+250 786 6347 776</a>
        </ContactBody>
      </ContactBodyContainer>
    </ContactUsContainer>
  );
};

export default ContactUs;
