import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavConatiner = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  z-index: 98;
  color: ${props => props.theme.color.black};
  font-weight: ${props => props.theme.fontWeight.bold};
  min-height: 50px;
  left: ${({ navOpen }) => (navOpen ? '0' : '-320px')};
  width: 320px;
  height: 100%;
  padding: 0;
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    height: 60px;
    width: 100%;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
  }

  ul {
    align-items: center;
  }
`;

export const MainNav = styled.ul`
  position: relative;
  margin: 0;
  top: 0;
  background-color: ${props => props.theme.color.white};
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.md}px;
  left: ${({ navOpen }) => (navOpen ? '320px' : '0')};
  display: flex;
  flex-direction: column;
  transition: left 0.5s;
  align-items: center;
  li {
    display: flex;
  }
`;

export const MobileNav = styled.ul`
  position: relative;
  top: 0;
  background-color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fontSize.xl}px;
  max-height: ${props => (props.expand ? '1000px' : '0px')};
  margin-top: ${({ theme }) => theme.navHeight - 1}px;
  overflow: hidden;
  width: 100%;
  align-items: center;
  text-align: center;

  li {
    width: 100%;
    padding: ${props => props.theme.space[2]}px 0;

    a {
      display: block;
      width: 100%;
    }
  }

  transition: max-height 0.5s ease;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.visbyCF};
  color: ${props => props.theme.color.black};
  display: flex;
  padding-left: 20px;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.color.lightGrey30};
    transition: background-color 0.2s;
  }
`;

export const MobileLinksContainer = styled.div`
  position: absolute;
  right: ${props => props.theme.space[8]}px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.ash};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  a {
    color: ${props => props.theme.color.ash};
    text-decoration: none;
    text-transform: uppercase;
    padding: 0;
  }
  ${NavLink} {
    margin-right: 8px;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export const BookNowBtn = styled.div`
  position: absolute;
  left: ${props => props.theme.space[3]}px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.ash};
  text-decoration: none;
  text-transform: uppercase;
  a {
    padding: 10px;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export const SocialIcon = styled.img`
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
`;
