import React from 'react';
import { HomeContainer, HomeImage, HomeBodyContainer, HomeBody } from './Home.styles';

const Home = () => {
  return (
    <HomeContainer>
      <HomeImage imgSrc="/img/home1.jpg" />
      <HomeBodyContainer>
        <HomeBody>Providing you digital solutions for your projects</HomeBody>
      </HomeBodyContainer>
    </HomeContainer>
  );
};

export default Home;
